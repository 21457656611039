import { React, useState , useEffect} from 'react'
import Cookies from 'universal-cookie';
import {v4 as uuidv4} from 'uuid';


import './App.css';

function loveCalculatorAdvanced(name1, name2) {
  // ... (name preparation as before) ...

  let score = 0;
  const combinedName = name1 + name2;

  for (let i = 0; i < combinedName.length; i++) {
      const charCode = combinedName.charCodeAt(i);
      score += charCode * (i + 1); // Multiply by position for variation
  }

  const percentage = Math.floor((score % 101)); // Modulo 101

  // ... (message generation as before) ...

  return percentage;
}


function loveCalculator(name1, name2) {
  // 1. Prepare the names (optional but recommended):
  name1 = name1.toLowerCase().trim();
  name2 = name2.toLowerCase().trim();

  // 2. Simple Scoring (replace with more complex logic if desired):
  let score1 = 0;
  let score2 = 0;

  for (let i = 0; i < name1.length; i++) {
    score1 += name1.charCodeAt(i); // Sum of character codes
  }
  for (let i = 0; i < name2.length; i++) {
    score2 += name2.charCodeAt(i);
  }

  // 3. Combine scores and generate a percentage:
  const combinedScore = score1 + score2;
  const percentage = Math.floor((combinedScore % 101)); // Modulo 101 to get a value between 0 and 100

  // 4.  Generate a fun message (optional):
  let message = "";
  if (percentage > 80) {
    message = "Great match!";
  } else if (percentage > 50) {
    message = "Not bad!";
  } else {
    message = "Could be better...";
  }

  // 5. Return the result:
  return percentage;
}

function App() {
  const [name, setName] = useState("");
  const [name2, setName2] = useState("");

  function editDistance(s1, s2) {
    s1 = s1.toLowerCase();
    s2 = s2.toLowerCase();
  
    var costs = new Array();
    for (var i = 0; i <= s1.length; i++) {
      var lastValue = i;
      for (var j = 0; j <= s2.length; j++) {
        if (i === 0)
          costs[j] = j;
        else {
          if (j > 0) {
            var newValue = costs[j - 1];
            if (s1.charAt(i - 1) != s2.charAt(j - 1))
              newValue = Math.min(Math.min(newValue, lastValue),
                costs[j]) + 1;
            costs[j - 1] = lastValue;
            lastValue = newValue;
          }
        }
      }
      if (i > 0)
        costs[s2.length] = lastValue;
    }
    return costs[s2.length];
  }

  function similarity(s1, s2) {
    var longer = s1;
    var shorter = s2;
    if (s1.length < s2.length) {
      longer = s2;
      shorter = s1;
    }
    var longerLength = longer.length;
    if (longerLength == 0) {
      return 1.0;
    }
    return (longerLength - editDistance(longer, shorter)) / parseFloat(longerLength);
  }

  function SetCookie() {
    const cookies = new Cookies();
    if (cookies.get('server-uuid') === undefined) {
      var uuid = uuidv4();
      cookies.set('server-uuid', uuid, { path: '/' });
      console.log("set uuid")
    }
  }
  function SendAPI(name1, name2) {
    const cookies = new Cookies();
      const options = {
        headers: new Headers({"X-Server-ID" : cookies.get('server-uuid')}),
    };

    fetch('https://lovecalculator.kopoutech.com/query?name1='+name1+"&name2="+name2 , options)
         .then((res) => res.json())
         .then((data) => {
            console.log(data);
         })
         .catch((err) => {
            console.log(err.message);
         });
  }

  function CalculateLove(name1,name2) {
    var n1 = name1.toLowerCase()+name2.toLowerCase()
    var n2 = name2.toLowerCase()+name1.toLowerCase()



    // var n1 = name1.toLowerCase()
    // var n2 = name2.toLowerCase()

    var maxVal = 127 * n1.length
    // var minVal = 97 * n1.length
    // var name_code = utf8Encode.encode(n1);
    // var name2_code = utf8Encode.encode(n2);
    var resBuf = new Array(n1.length)
    for (var i=0; i<n1.length; i++) {
      // console.log(n1.charCodeAt(i))
      resBuf[i] = n1.charCodeAt(i) & n2.charCodeAt(i)
    }

    var result = Math.round(resBuf.reduce((partialSum, a) => partialSum + a, 0) / maxVal * 100);
    console.log(result)
    console.log(maxVal)
    console.log(resBuf)
    console.log(n1)
    console.log(n2)

    var s = similarity(name1, name2)
    console.log('Similarity: %d',s)
    return Math.round((result + s*100) / (result + 100) * 100)
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    SetCookie()
    SendAPI(name, name2)
    
    if (name.toLocaleLowerCase() === name2.toLocaleLowerCase()){
      alert(`The name you entered was: ${name} and ${name2}. Your Love percentage is 100. You deserve to love yourself`)
    } else {
      var res = Math.max(loveCalculator(name, name2), loveCalculatorAdvanced(name, name2), CalculateLove(name, name2))
      alert(`The name you entered was: ${name} and ${name2}. Your Love percentage is ${res}`)
    }
  }
  
  return (
    <>
      <div className="flex-grid tborder">
        <div id="header" className='letter-item'>
            Love Calculator
        </div>
        <div id="question" className='form'>
        <form onSubmit={handleSubmit} className='form'>
          <label>Enter Your Name: &nbsp;
            <input 
              type="text" 
              value={name}
              onChange={(e) => setName(e.target.value)}
              required={true}
              />
          </label>
          <br/>
          <label>Enter Your Partner's Name: &nbsp;
            <input 
              type="text" 
              value={name2}
              onChange={(e) => setName2(e.target.value)}
              required={true}
              />
          </label>
              <br/>
          <input type="submit" />
        </form>
        </div>
        <div id="result">

        </div>
      </div>
    </>
  );
}

export default App;
